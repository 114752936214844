import React from "react";
import { Card, CardContent, Box } from "@mui/material";
import { IMAGES } from "../../utils/constants";

export const RegistrationCard = ({ children }) => {
  return (
    <Card sx={{ pb: 1, px: 2, pt: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box component="img" src={IMAGES.LOGO_SHIELD_DARK} sx={{ maxWidth: 120, maxHeight: 120, opacity: 0.2, position: "absolute", mt: 0 }} />
      </Box>
      <CardContent>{children}</CardContent>
    </Card>
  );
};
