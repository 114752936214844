import React from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../assets/lottie/auth-screen-globe.json";
import { Box } from "@mui/material";

export const AuthScreenGlobe = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
  };

  return (
    <Box sx={{ opacity: 0.1 }}>
      <Lottie loop play animationData={loadingAnimation} style={{ width: 450 }} />
    </Box>
  );
};
