import React from "react";
import { Dialog, DialogContent, Divider, useMediaQuery, useTheme, Box, IconButton, Typography, List, ListItem, ListItemAvatar, ListItemText, Chip } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import { zoomableImage } from "../../../utils/image-helper";

export const ConverterResultDetails = ({ open, onClose, converter, imageCount }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog open={open} fullScreen={isMobile} fullWidth maxWidth="md" PaperProps={{ sx: { borderRadius: "22px" } }} bac>
      <DialogContent sx={{ p: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose} color="primary" size="large">
            <Clear />
          </IconButton>
        </Box>
        <Typography sx={{ fontSize: 34, fontWeight: 700, lineHeight: "41.99px", letterSpacing: "0.25px", mb: 5 }}>Details</Typography>
        <Box>
          <Typography color="primary" sx={{ fontSize: 20, fontWeight: 500, lineHeight: "160%", letterSpacing: "0.15px" }}>
            {converter.code}
          </Typography>
          <Typography sx={{ fontSize: 48, fontWeight: 600, lineHeight: "116.7%" }}>
            <NumericFormat value={converter.price} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
          </Typography>
          <Typography sx={{ ml: 1, fontSize: 14, fontWeight: 500, lineHeight: "157%", letterSpacing: "0.1px" }}>Average Sample</Typography>
        </Box>
        <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: "26px", letterSpacing: "0.46px", textTransform: "uppercase", mt: 2 }}>Total Samples: {converter.samples?.length ?? 0}</Typography>
        {converter.samples?.length > 0 ? (
          <List
            sx={{
              maxHeight: "calc(80px * 4)",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.1)",
              },
            }}>
            {converter.samples?.map((sample, index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: 20, fontWeight: 700, lineHeight: "160%", letterSpacing: 0.15 }}>
                      <NumericFormat value={sample.price} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
                    </Typography>
                  }
                  secondary={`#UCC${sample.id}`}
                />
                <ListItemAvatar>
                  <Chip label={`Sample ${index + 1}`} variant="outlined" color="primary" />
                </ListItemAvatar>
              </ListItem>
            ))}
          </List>
        ) : (
          <List
            sx={{
              maxHeight: "calc(80px * 4)",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.1)",
              },
            }}>
            <ListItem key={converter.id} divider>
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: 20, fontWeight: 700, lineHeight: "160%", letterSpacing: 0.15 }}>
                    <NumericFormat value={converter.price} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
                  </Typography>
                }
                secondary={`#UCC${converter.id}`}
              />
              <ListItemAvatar>
                <Chip label={`Sample 1`} variant="outlined" color="primary" />
              </ListItemAvatar>
            </ListItem>
          </List>
        )}

        <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: "26px", letterSpacing: "0.46px", textTransform: "uppercase", mt: 5 }}>Total Photos: {imageCount}</Typography>
        {imageCount > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3, mt: 3 }}>
            {converter?.imageSets?.map((imageSet, index) => {
              var images = imageSet?.images?.map((image, index2) => {
                return zoomableImage(image);
              });

              return (
                <>
                  {imageSet?.frontImage && zoomableImage(imageSet?.frontImage, true)}
                  {imageSet?.backImage && zoomableImage(imageSet?.backImage, true)}
                  {imageSet?.codeImage && zoomableImage(imageSet?.codeImage, true)}
                  {images}
                </>
              );
            })}
          </Box>
        ) : (
          <Box sx={{ mx: 2, mt: 1 }}>
            <Typography sx={{ fontSize: 20, fontStyle: "italic", fontWeight: 600, lineHeight: "160%", letterSpacing: "0.15px", mb: 4 }} color="text.secondary">
              No photos currently available. Check back soon, we are always updating!
            </Typography>
            <Divider />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
