import React from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../assets/lottie/loading-animation.json";

export const Spinner = ({ size = 200 }) => {
  return (
    <div>
      <Lottie loop play animationData={loadingAnimation} style={{ width: size, height: size }} />
    </div>
  );
};
