import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PrivateOutlet from "./PrivateOutlet";
import NotFound from "./pages/NotFound";
import Converters from "./pages/Converters";
import "react-medium-image-zoom/dist/styles.css";
import Vehicles from "./pages/Vehicles";
import ForgotPassword from "./pages/ForgotPassword";
import VehiclesYmme from "./pages/VehiclesYmme";
import { useContext } from "react";
import { AuthContext } from "./contexts/AuthContext";

export default function App() {
  const { currentUser, currentClaims } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<PrivateOutlet />}>
        <Route path="/" element={<Home />} />
        {currentClaims?.ue_catId && <Route path="/converters" element={<Converters />} />}
        {currentClaims?.ue_vehicles && <Route path="/vehicles" element={<Vehicles />} />}
        {currentClaims?.ue_vehicles && <Route path="/vehicles/ymme" element={<VehiclesYmme />} />}
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
}
