import { Box, Typography, Card, Button, Divider, Chip, CardActions } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { nonZoomableImage, zoomableImage } from "../../../utils/image-helper";
import { IMAGES } from "../../../utils/constants";
import { useState } from "react";
import { ConverterResultDetails } from "./ConverterResultDetails";

export const ConverterResultCard = ({ converterResult }) => {
  const [openDetails, setOpenDetails] = useState(false);

  const imageCount =
    converterResult.imageSets?.reduce((count, imageSet) => {
      let imageCount = imageSet.images?.length ?? 0;
      if (imageSet.frontImage) {
        imageCount++;
      }
      if (imageSet.codeImage) {
        imageCount++;
      }
      if (imageSet.backImage) {
        imageCount++;
      }
      return count + imageCount;
    }, 0) ?? 0;

  return (
    <>
      <Card
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
        }}>
        <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography
            variant="h4"
            color="primary.main"
            sx={{
              fontWeight: 500,
              fontSize: 20,
              lineHeight: "160%",
              letterSpacing: "0.15px",
            }}>
            {converterResult.code}
          </Typography>
          <Chip
            color="primary"
            label={
              <Typography
                variant="h4"
                color="text.primary"
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  lineHeight: "18px",
                  letterSpacing: "0.16px",
                  color: "primary",
                }}>
                {converterResult.make.name ?? "N/A"}
              </Typography>
            }
            variant="outlined"
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontSize: 34, fontWeight: 700, lineHeight: "123.5%", letterSpacing: 0.25 }}>
            <NumericFormat value={converterResult.price} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{
                fontWeight: 500,
                fontSize: 14,
                lineHeight: "24px",
                letterSpacing: "0.17px",
              }}>
              Samples
            </Typography>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{
                fontWeight: 500,
                fontSize: 14,
                lineHeight: "24px",
                letterSpacing: "0.17px",
              }}>
              {converterResult.samples?.length == 0 ? 1 : converterResult.samples?.length}
            </Typography>
          </Box>
          <Divider sx={{ mt: 1 }} />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{
                fontWeight: 500,
                fontSize: 14,
                lineHeight: "24px",
                letterSpacing: "0.17px",
              }}>
              Photos
            </Typography>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{
                fontWeight: 500,
                fontSize: 14,
                lineHeight: "24px",
                letterSpacing: "0.17px",
              }}>
              {imageCount}
            </Typography>
          </Box>
          <Divider sx={{ mt: 1 }} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2, mt: 1, mb: 3 }}>
          {converterResult.primaryImageSet?.frontImage ? zoomableImage(converterResult.primaryImageSet?.frontImage, true) : nonZoomableImage(IMAGES.CATALYTIC_CONVERTER, true)}
          {converterResult.primaryImageSet?.backImage ? zoomableImage(converterResult.primaryImageSet?.backImage, true) : nonZoomableImage(IMAGES.CATALYTIC_CONVERTER, true)}
          {converterResult.primaryImageSet?.codeImage ? zoomableImage(converterResult.primaryImageSet?.codeImage, true) : nonZoomableImage(IMAGES.CATALYTIC_CONVERTER, true)}
        </Box>
        <CardActions sx={{ mt: 3 }}>
          <Button fullWidth variant="contained" size="large" onClick={() => setOpenDetails(true)}>
            <Typography sx={{ fontSize: 14, fontWeight: 500, lineHeight: "24px", letterSpacing: "0.4px", textTransform: "uppercase" }}>View Details</Typography>
          </Button>
        </CardActions>
      </Card>
      {converterResult && <ConverterResultDetails converter={converterResult} open={openDetails} onClose={() => setOpenDetails(false)} imageCount={imageCount} />}
    </>
  );
};
