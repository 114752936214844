import React from "react";
import { Card, CardContent, Box } from "@mui/material";
import { IMAGES } from "../../utils/constants";

export const AuthenticationCard = ({ children }) => {
  return (
    <Card
      sx={{
        pb: { xs: 1, md: 2, lg: 3, xl: 8 },
        px: { xs: 1, md: 2, lg: 3, xl: 5 },
        pt: { xs: 1, md: 2, lg: 3, xl: 5 },
      }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box component="img" src={IMAGES.LOGO_SHIELD_DARK} sx={{ maxWidth: 164, maxHeight: 193, opacity: 0.2, position: "absolute", mt: -2 }} />
      </Box>
      <CardContent>{children}</CardContent>
    </Card>
  );
};
