// include all necessary imports
import React, { useState } from "react";
import { Button, Dialog, DialogActions, Grid, TextField, Typography, useMediaQuery, useTheme, IconButton, Box, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import CatalyticConverterService from "../services/converter-service";
import { ManageSearch, Close } from "@mui/icons-material";
import { Spinner } from "./animations/Spinner";
import { Completed } from "./animations/Completed";
import LeadService from "../services/lead-service";

const BulkSearchButton = () => {
  const [multipleVins, setMultipleVins] = useState("");
  const [possibleVins, setPossibleVins] = useState([]);
  const [vinResults, setVinResults] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sendToUcc, setSendToUcc] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const searchMultipleVins = async () => {
    var converterSets = await bulkConverterMutation.mutateAsync(possibleVins);
    if (sendToUcc) {
      await sendVinsToSalesMutation.mutateAsync(possibleVins);
    }
    setVinResults(converterSets);
    downloadResultFile(converterSets);
  };

  const downloadResultFile = (converterSets) => {
    const headers = `VIN,Year,Make,Model,Reasoning,Most Common Set Price,Lowest Set Price,Highest Set Price,Number of Sets\n`;
    var totals = {
      mostCommon: 0,
      lowest: 0,
      highest: 0,
    };
    const csvContent =
      "data:text/csv;charset=utf-8," +
      headers +
      converterSets?.data?.result
        .map((catResult) => {
          totals.mostCommon += catResult?.mostCommonPrice ?? 0;
          totals.lowest += catResult?.lowestPrice ?? 0;
          totals.highest += catResult?.highestPrice ?? 0;

          return `${catResult.vin},${catResult?.vehicle?.year ?? ""},${catResult?.vehicle?.make ?? ""},${catResult?.vehicle?.model ?? ""},${catResult?.label ?? ""},${catResult?.mostCommonPrice?.toFixed(2) ?? ""},${
            catResult?.lowestPrice?.toFixed(2) ?? ""
          },${catResult?.highestPrice?.toFixed(2) ?? ""},${catResult?.results?.length ?? ""}`;
        })
        .join("\n")
        .concat(`\n,,,,Totals:,${totals.mostCommon?.toFixed(2)},${totals.lowest?.toFixed(2)},${totals.highest?.toFixed(2)},\n`);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    // link.setAttribute("download", "vehicles.csv");
    // make the file in this format ucc-cats-yyyy-mm-dd hh_mm_ss.csv, make sure its local time
    link.setAttribute("download", "ucc-cats-" + new Date().toISOString().replace(/:/g, "_").replace(/T/g, " ").split(".")[0] + ".csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleMultipleVinsChange = (e) => {
    setMultipleVins(e.target.value);
    setPossibleVins(e.target.value.split("\n").filter((vin) => vin));
  };

  const bulkConverterMutation = useMutation({ mutationFn: (vins) => CatalyticConverterService.getCascadingSearchSimple(vins) });
  const sendVinsToSalesMutation = useMutation({ mutationFn: (vins) => LeadService.sendVinsToSales(vins) });

  const renderHeaderInfo = () => {
    if (bulkConverterMutation.isPending || sendVinsToSalesMutation.isPending)
      return (
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ fontSize: 34, fontWeight: 700, lineHeight: "123.5%", letterSpacing: 0.25 }}>
            Loading Results...
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: "150%", mt: 0.5, letterSpacing: 0.15, fontSize: 18 }}>
            Working on your results! Give us just a few more seconds...
          </Typography>
        </Grid>
      );
    else if (vinResults) {
      return (
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ fontSize: 34, fontWeight: 700, lineHeight: "123.5%", letterSpacing: 0.25 }}>
            Completed
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: "150%", mt: 0.5, letterSpacing: 0.15, fontSize: 18 }}>
            Your file is now downloading. If the download hasn't automatically started, click the re-download button below.
          </Typography>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ fontSize: 34, fontWeight: 700, lineHeight: "123.5%", letterSpacing: 0.25 }}>
            Bulk VIN Search
          </Typography>
        </Grid>
      );
    }
  };

  const renderContent = () => {
    if (bulkConverterMutation.isPending || sendVinsToSalesMutation.isPending) return <Spinner />;
    else if (vinResults) {
      return <Completed />;
    } else {
      return (
        <Box sx={{ flex: 1 }}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Enter Multiple VINs"
            multiline
            minRows={9}
            maxRows={9}
            value={multipleVins}
            InputProps={{ style: { color: theme.palette.primary.main } }}
            onChange={(e) => handleMultipleVinsChange(e)}
            helperText={`Enter multiple VINs, one per line. You have entered ${possibleVins.length} VINs.`}
          />
          <FormControl>
            <Typography sx={{ fontSize: 18, fontWeight: 400, lineHeight: "150%", letterSpacing: "0.15px", opacity: 0.6, mt: 5 }}>Would you like to start a shipment to UCC with the converters on these VINs?</Typography>
            <RadioGroup name="controlled-radio-buttons-group" value={sendToUcc} onChange={(event) => setSendToUcc(event.target.value)}>
              <FormControlLabel value={true} control={<Radio />} label={<Typography sx={{ fontSize: 18, fontWeight: 400, lineHeight: "150%", letterSpacing: "0.15px" }}>Yes</Typography>} sx={{ ml: 1 }} />
              <FormControlLabel value={false} control={<Radio />} label={<Typography sx={{ fontSize: 18, fontWeight: 400, lineHeight: "150%", letterSpacing: "0.15px" }}>No</Typography>} sx={{ ml: 1 }} />
            </RadioGroup>
          </FormControl>
        </Box>
      );
    }
  };

  const renderActions = () => {
    if (vinResults)
      return (
        <Button onClick={() => downloadResultFile(vinResults)} variant="contained" color="primary" size="large" sx={{ width: "40%" }}>
          <Typography sx={{ fontSize: 22.217, fontWeight: 600, lineHeight: "162.5%", letterSpacing: 0.639, textTransform: "uppercase" }}>Re-Download File</Typography>
        </Button>
      );
    else {
      return (
        <Button onClick={() => searchMultipleVins()} variant="contained" color="primary" size="large" sx={{ width: "40%" }} disabled={bulkConverterMutation.isPending || sendToUcc === undefined || sendVinsToSalesMutation.isPending}>
          <Typography sx={{ fontSize: 22.217, fontWeight: 600, lineHeight: "162.5%", letterSpacing: 0.639, textTransform: "uppercase" }}>Bulk Search</Typography>
        </Button>
      );
    }
  };

  const onClose = () => {
    setDialogOpen(false);
    setSendToUcc();
    setVinResults(null);
    setMultipleVins("");
    setPossibleVins([]);
  };

  return (
    <>
      <Button size="large" variant="outlined" sx={{ my: 3 }} startIcon={<ManageSearch />} onClick={() => setDialogOpen(true)}>
        BULK VIN SEARCH
      </Button>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullScreen={isMobile} fullWidth maxWidth="md" PaperProps={{ sx: { borderRadius: 5.5, p: 5 } }}>
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <IconButton color="primary" size="large" onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Grid>
          {renderHeaderInfo()}
          <Grid item xs={12} sx={{ mt: 5, justifyContent: "center", display: "flex" }}>
            {renderContent()}
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 0,
            mt: 5,
          }}>
          {renderActions()}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkSearchButton;
