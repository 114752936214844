import api from "../utils/axios-client";

const getMe = async () => api.get("v1/users/me");

const selfRegisterLead = async (data) => api.post("v2/users/leads/self-register", data);

const UserService = {
  getMe,
  selfRegisterLead,
};

export default UserService;
