import { AppBar, Box, Container, Toolbar, Typography, Link, IconButton, useTheme } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Link as RouterLink } from "react-router-dom";
import { IMAGES, QUERIES } from "../utils/constants";
import { Menu, Logout } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import UserService from "../services/user-service";
import CustomDrawer from "./CustomDrawer";
import { useResponsiveDrawer } from "../hooks/useResponsiveDrawer";
import { useIsBelowMedium } from "../hooks/useIsBelowMedium";

const drawerWidth = "240px";
const appBarHeight = "64px"; // Adjust based on your AppBar's height or theme.spacing

export const PageLayout = (props) => {
  const { currentUser, logOut } = useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);

  const theme = useTheme();
  const { isDesktop } = useResponsiveDrawer();
  const { belowMedium } = useIsBelowMedium();
  const { data: userData, isFetching: getMeFetching } = useQuery({ queryKey: [QUERIES.Users, "me"], queryFn: () => UserService.getMe() });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    await logOut();
  };

  useEffect(() => {
    if (mobileOpen) setMobileOpen(false);
  }, [belowMedium]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav" position="fixed" color="primary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { lg: "none" } }}>
                  <Menu />
                </IconButton>
                <Link component={RouterLink} to="/" color="inherit" variant="h6">
                  <Box component="img" src={IMAGES.LOGO_LIGHT} sx={{ height: 24, mt: 1 }} />
                </Link>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ mt: 1, mr: 1.5, fontSize: 16, fontWeight: 500, lineHeight: "150%", letterSpacing: 0.15, display: { xs: "none", md: "block" } }}>{currentUser?.email}</Typography>
                <IconButton color="inherit" aria-label="log out" onClick={handleLogout}>
                  <Logout />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <CustomDrawer drawerWidth={drawerWidth} appBarHeight={appBarHeight} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: isDesktop ? { lg: `calc(100% - ${drawerWidth})` } : "100%",
            p: { xs: 1, sm: 2, md: 3, lg: 4, xl: 8 },
            position: "fixed",
            left: isDesktop ? drawerWidth : 0,
            height: "100%",
            zIndex: 0,
            backgroundImage: `url(${IMAGES.BG})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "auto",
          }}>
          <Toolbar />
          <Container maxWidth={false}>{props.children}</Container>
        </Box>
      </Box>
    </>
  );
};
