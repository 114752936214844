import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#194185",
    },
    cards: {
      cardHeaderLight: "#EDF0F5",
      cardHeaderLightContrastText: "#112D5D",
      cardDivider: "rgba(0, 0, 0, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.60)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "Inter",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontWeight: 600,
      fontSize: "2.375rem",
      lineHeight: 1.21,
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.875rem",
      lineHeight: 1.27,
    },
    h3: {
      fontWeight: 600,
      fontSize: 48,
      lineHeight: "56.02px",
    },
    h4: {
      fontSize: "34px",
      fontWeight: 500,
      lineHeight: "41.99px",
      letterSpacing: "0.25px",
    },
    h5: {
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: "24px",
      lineHeight: 1.334,
    },
    h6: {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "0.15px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
    },
    body1: {
      fontSize: "0.875rem",
      lineHeight: 1.57,
    },
    body2: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.43,
      letterSpacing: "0.17px",
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: 1.57,
    },
    subtitle2: {
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: 1.66,
    },
    overline: {
      lineHeight: 2.66,
      fontWeight: 400,
      fontSize: "12px",
      letterSpacing: "1px",
    },
    button: {
      textTransform: "uppercase",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {},
        sizeLarge: {
          borderRadius: 8,
          padding: "12px 24px",
        },
        text: {
          fontWeight: 600,
          fontSize: 16,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 8,
          "&:last-child": {
            paddingBottom: 8,
          },
        },
      },
    },
  },
});

export default theme;
