import React from "react";
import { useTheme, Grid, Container, Box, Link, Typography } from "@mui/material";
import { IMAGES } from "../../utils/constants";
import { AuthScreenGlobe } from "../animations/AuthScreenGlobe";

const AuthenticationLayout = ({ children }) => {
  const theme = useTheme();

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container>
        <Grid
          item
          sm={12}
          md={6.5}
          sx={{ backgroundImage: `url(${IMAGES.AUTH_BG_LEFT})`, height: "100VH", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", display: "flex", justifyContent: "center", alignItems: "center", display: { xs: "none", md: "flex" } }}>
          <Box component="img" src={IMAGES.LOGO_LIGHT} sx={{ height: 80, position: "absolute" }} />
          <AuthScreenGlobe />
        </Grid>
        <Grid item container xs={12} md={5.5}>
          <Grid
            item={12}
            sx={{
              backgroundImage: `url(${IMAGES.AUTH_BG_RIGHT})`,
              height: { xs: "auto", md: "100VH" },
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: { xs: "flex-start", md: "center" },
              alignItems: "center",
              width: "100%",
              flexDirection: "column",
              display: "flex",
              p: { xs: 2, md: 4 },
            }}>
            {children}
          </Grid>
          <Box sx={{ position: { xs: "auto", md: "absolute" }, bottom: theme.spacing(2), right: theme.spacing(2) }}>
            <Typography variant="subtitle2" underline="hover" mr={2} component={Link} href="https://google.com" target="_blank">
              Privacy Policy
            </Typography>
            <Typography variant="subtitle2" underline="hover" mr={2} component={Link} href="https://google.com" target="_blank">
              Terms & Conditions
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AuthenticationLayout;
