import api from "../utils/axios-client";

/**
 * Gets all vehicle years
 */
const getYears = async () => api.get(`/v1/vehicles/years`);

/**
 * Gets all vehicle Makes by year
 */
const getMakes = async (years = null, onlyApproved = false) => api.post(`/v1/vehicles/makes`, { years, onlyApproved });

/**
 * Gets all vehicle models by year and make
 */
const getModels = async (years = null, makes = null, onlyApproved = false) => api.post(`/v1/vehicles/models`, { years, makes, onlyApproved });

/**
 * Gets all vehicle engines by year and make
 */
const getEngines = async (years = null, makes = null, models = null, onlyApproved = false) => api.post(`/v1/vehicles/engines`, { years, makes, models, onlyApproved });

export default { getYears, getMakes, getModels, getEngines };
