import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const ConverterIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="21.5" y="16.0946" width="2" height="7" transform="rotate(-180 21.5 16.0946)" fill="#194185" />
        <rect x="4.5" y="16.0946" width="2" height="7" transform="rotate(-180 4.5 16.0946)" fill="#194185" />
        <rect x="18.5" y="18.0946" width="13" height="11" transform="rotate(-180 18.5 18.0946)" fill="#194185" />
        <rect width="6.86471" height="2" rx="0.5" transform="matrix(-0.0059438 -0.999982 0.999926 -0.0121465 10.5 16.0946)" fill="#F9F9F9" />
        <rect width="6.86471" height="2" rx="0.5" transform="matrix(-0.0059438 -0.999982 0.999926 -0.0121465 7.5 16.0946)" fill="#F9F9F9" />
      </svg>{" "}
    </SvgIcon>
  );
};
