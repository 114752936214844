import { Grid, TextField, Typography, useTheme, IconButton, Box, Alert, Button, Divider } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../utils/constants";
import OverlayLoader from "../ui-components/loaders/OverlayLoader";
import { useFormik } from "formik";
import * as yup from "yup";
import CatalyticConverterService from "../services/converter-service";
import { Clear, Search } from "@mui/icons-material";
import { CascadingSearchCard } from "../ui-components/cards/result-cards/CascadingSearchCard";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import { CascadingSearchAccuracy } from "../ui-components/CascadingSearchAccuracy";
import BulkSearchButton from "../ui-components/BulkSearchButton";
import UnitedEcosystemHeaderText from "../ui-components/UnitedEcosystemHeaderText";
import { useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";

const Vehicles = () => {
  const theme = useTheme();
  const [previousSearch, setPreviousSearch] = useState();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      search: new URLSearchParams(window.location.search).get("vin") || "",
    },
    validationSchema: yup.object({
      search: yup.string().required("A VIN is required.").min(17, "VIN must be 17 characters long.").max(17, "VIN must be 17 characters long."),
    }),
    onSubmit: (values) => {
      refetch();
      setPreviousSearch(values.search);
    },
  });

  const { data: vehicles, isFetching: convertersLoading, refetch } = useQuery({ queryKey: [QUERIES.Converters, "cascading-search"], queryFn: () => CatalyticConverterService.getCascadingSearchSimple([formik.values.search]), enabled: false });

  useEffect(() => {
    if (formik.values.search) {
      refetch();
    }
    return () => {
      queryClient.removeQueries({ queryKey: [QUERIES.Converters, "cascading-search"] });
      setPreviousSearch(); // clear last search when component unmounts
    };
  }, []);

  const firstResult = vehicles?.data?.result && vehicles?.data?.result[0];

  const resultforLabel = firstResult?.results;

  const anyMostCommonInSearchResult = resultforLabel?.some((cascadeResult) => cascadeResult.isMostCommon);

  const lowestTotalPrice = resultforLabel?.reduce((lowestPrice, cascadeResult) => {
    const totalPrice = cascadeResult.totalPrice;
    if (totalPrice < lowestPrice || lowestPrice === undefined) {
      return totalPrice;
    }
    return lowestPrice;
  }, undefined);

  const cascadingSearchResultCards = useMemo(
    () => resultforLabel?.map((cascadeResult, index) => <CascadingSearchCard cascadeDetails={cascadeResult} index={index} key={index} anyMostCommonInSearchResult={anyMostCommonInSearchResult} />),
    [resultforLabel]
  );

  return (
    <>
      {convertersLoading && <OverlayLoader />}
      <Grid container sx={{ pt: { xs: 0, sm: 2 } }} spacing={1}>
        <Grid item xs={12} md={8}>
          <UnitedEcosystemHeaderText text={"United Ecosystem"} />
          <Typography variant="h4" sx={{ fontSize: 34, fontWeight: 700, lineHeight: "123.5%", letterSpacing: 0.25, mt: 1 }}>
            VIN Search
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: { xs: "none", md: "flex" }, justifyContent: "flex-end", mb: -2 }}>
          <BulkSearchButton />
        </Grid>
        <Grid item xs={12} md={9}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item md={3} />
        <Grid item xs={12} sm={6} sx={{ mb: 1 }} component="form" onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            autoComplete="off"
            size="large"
            id="search"
            name="search"
            label="Search"
            value={formik.values.search}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.search && Boolean(formik.errors.search)}
            helperText={formik.touched.search && formik.errors.search}
            InputProps={{
              endAdornment: (
                <IconButton type="submit" onClick={formik.handleSubmit}>
                  <Search />
                </IconButton>
              ),
              startAdornment: formik?.values.search && (
                <IconButton onClick={() => formik.resetForm()}>
                  <Clear />
                </IconButton>
              ),
            }}
          />
        </Grid>
        {previousSearch &&
          (vehicles?.data && vehicles?.data?.result?.length === 0 ? (
            <Grid item xs={12} mb={1}>
              <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                No results found for "{previousSearch}", please try another VIN.
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12} mb={1}>
              <Typography sx={{ fontSize: 16, fontStyle: "italic", fontWeight: 400, lineHeight: "175%", letterSpacing: 0.15 }}>Showing results for "{previousSearch}"</Typography>
            </Grid>
          ))}

        <Grid item container xs={12} mb={1} mt={3}>
          <Grid item xs={12} md={6} xl={8} alignContent="end">
            {firstResult?.vehicle && (
              <Typography color="primary" sx={{ fontSize: 16, fontWeight: 700, lineHeight: "150%", letterSpacing: 0.15 }}>
                {firstResult?.vehicle?.year} {firstResult?.vehicle?.make} {firstResult?.vehicle?.model} {firstResult?.vehicle?.engineLiters}L
              </Typography>
            )}
            <Box display="flex" flexDirection="row">
              {(firstResult?.mostCommonPrice || lowestTotalPrice) && (
                <Typography color="primary" sx={{ fontSize: 60, fontWeight: 500, lineHeight: "120%", letterSpacing: -0.5 }}>
                  <NumericFormat value={firstResult?.mostCommonPrice ?? lowestTotalPrice} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
                </Typography>
              )}
              {firstResult?.lowestPrice !== firstResult?.highestPrice && (
                <Box display="flex" flexDirection="column" ml={2} mt={1}>
                  <Typography sx={{ fontSize: 18, fontWeight: 400, lineHeight: "150%", letterSpacing: 0.15 }} color="primary">
                    Price Range
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: 400, lineHeight: "150%", letterSpacing: 0.15 }} color="primary">
                    <NumericFormat value={firstResult?.lowestPrice} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
                    {" - "}
                    <NumericFormat value={firstResult?.highestPrice} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            {firstResult?.vehicle && <CascadingSearchAccuracy label={firstResult?.label} />}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={6} pt={6}>
        {cascadingSearchResultCards}
      </Grid>
    </>
  );
};

export default Vehicles;
