import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { useContext } from "react";
import { AuthContext } from "./contexts/AuthContext";
import { PageLayout } from "./ui-components/PageLayout";

const PrivateOutlet = () => {
  const { currentUser, currentClaims } = useContext(AuthContext);
  var location = useLocation();

  return currentUser && currentClaims?.ue ? (
    <PageLayout>
      <Outlet />
    </PageLayout>
  ) : location?.pathname == "/vehicles" && location?.search ? (
    <Navigate to={`/register?redirect=${location.pathname}${location.search}`} />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateOutlet;
