import React, { useContext } from "react";
import { Grid, Box, Divider } from "@mui/material";
import { AuthContext } from "../contexts/AuthContext";
import UnitedEcosystemHeaderText from "../ui-components/UnitedEcosystemHeaderText";
import { IMAGES } from "../utils/constants";
import HomeScreenCard from "../ui-components/HomeScreenCard";

const Home = () => {
  const { currentClaims } = useContext(AuthContext);

  const pages = [
    {
      title: "VIN Search",
      description: "Search for single or multiple catalytic converters by VINs.",
      path: "/vehicles",
      buttonLabel: "Search",
      permission: currentClaims?.ue_vehicles,
    },
    {
      title: "YMME Search",
      description: "This YMME Tool is still a prorotype. Please enjoy testing it out!",
      path: "/vehicles/ymme",
      buttonLabel: "Search",
      permission: currentClaims?.ue_vehicles,
    },
    {
      title: "Converter ID",
      description: "Search for a converter by code.",
      path: "/converters",
      buttonLabel: "Search",
      permission: currentClaims?.ue_catId,
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 2 }} spacing={1}>
        <Grid item xs={12}>
          <UnitedEcosystemHeaderText text={"Welcome to UCC's"} />
          <Box component={"img"} src={IMAGES.LOGO_DARK} sx={{ maxHeight: 47, mt: 1.5 }} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item md={3} />
      </Grid>
      <Grid container sx={{ pt: 2 }} spacing={6}>
        {pages.map(
          (page, index) =>
            page.permission && (
              <Grid item xs={12} md={6} xl={4} key={index}>
                <HomeScreenCard title={page.title} description={page.description} buttonLabel={page.buttonLabel} path={page.path} />
              </Grid>
            )
        )}
      </Grid>
    </>
  );
};

export default Home;
