import api from "../utils/axios-client";

/**
 * Gets all catalytic converters by search
 */
const getSimpleConverters = async (search) =>
  api.get("/v1/catalytic-converters/simple", {
    params: { search },
  });

/**
 * Gets all auto complete converters
 */
const getAutoCompleteConverters = async () =>
  api.get("/v1/catalytic-converters/simple/auto-complete");

/**
 * Gets all cascading search data via list of a vins
 * @param {Array} vins *
 */
const getCascadingSearch = async (vins) =>
  api.post(`/v1/catalytic-converters/detailed/cascading-search/as-user`, vins);

/**
 * Gets all cascading search data via list of a vins
 * @param {Array} vins *
 */
const getCascadingSearchSimple = async (vins) =>
  api.post(`/v2/catalytic-converters/search`, vins);

/**
 *  Gets all cascading search data via year, make, model, and engine liters
 * @param {number} year
 * @param {string} make
 * @param {string} model
 * @param {string} engineLiters
 */
const getCascadingSearchSimpleYearMakeModelEngine = async (
  year,
  make,
  model,
  engineLiters
) =>
  api.post(`/v2/catalytic-converters/search/year-make-model-engine`, {
    year,
    make,
    model,
    engineLiters,
  });

const ConverterService = {
  getSimpleConverters,
  getAutoCompleteConverters,
  getCascadingSearch,
  getCascadingSearchSimple,
  getCascadingSearchSimpleYearMakeModelEngine,
};

export default ConverterService;
