import { Box } from "@mui/material";
import Zoom from "react-medium-image-zoom";

export const zoomableImage = (img, borderRadius = false) => {
  return (
    <Zoom key={`cat-image-${img?.id}`} zoomImg={{ src: img?.url }}>
      <Box sx={{ maxHeight: 80, maxWidth: 80, borderRadius: borderRadius && 2 }} component="img" src={img?.thumbnailUrl} />
    </Zoom>
  );
};

export const nonZoomableImage = (imageUrl, borderRadius = false) => {
  return <Box sx={{ maxHeight: 65, maxWidth: 65, borderRadius: borderRadius && 2 }} component="img" src={imageUrl} />;
};
