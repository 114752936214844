import React, { useState, memo, useMemo } from "react";
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Box } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { IMAGES } from "../utils/constants";
import { Home, DirectionsCar } from "@mui/icons-material";
import { ConverterIcon } from "./icons/ConverterIcon";
import { AuthContext } from "../contexts/AuthContext";
import { useContext } from "react";

const CustomDrawer = memo(({ drawerWidth, appBarHeight, mobileOpen, handleDrawerToggle }) => {
  const [openSubMenus, setOpenSubMenus] = useState({});
  const location = useLocation();

  const { currentClaims } = useContext(AuthContext);

  const navItemList = [
    {
      name: "Home",
      path: "/",
      icon: <Home color="primary" />,
    },
  ];

  const converterNavItem = {
    name: "Converter ID",
    path: "/converters",
    icon: <ConverterIcon color="primary" />,
  };

  const vehicleNavItem = {
    name: "BidTool",
    path: "/vehicles",
    icon: <DirectionsCar color="primary" />,
    subItems: [
      {
        name: "Search by VIN",
        path: "/vehicles",
      },
      {
        name: "Search by YMME (BETA)",
        path: "/vehicles/ymme",
      },
    ],
  };

  const navItems = useMemo(() => {
    var navItemsReturned = [...navItemList];

    if (currentClaims?.ue_vehicles) navItemsReturned.push(vehicleNavItem);
    if (currentClaims?.ue_catId) navItemsReturned.push(converterNavItem);

    return navItemsReturned;
  }, [currentClaims]);

  const handleToggleSubMenu = (event, name) => {
    event.stopPropagation();
    setOpenSubMenus((prevState) => ({ ...prevState, [name]: !prevState[name] }));
  };

  const renderNavItems = () => {
    return navItems.map((item) => {
      const isSubMenuOpen = openSubMenus[item.name] || false;

      return (
        <React.Fragment key={item.name}>
          {item.subItems ? (
            <ListItemButton onClick={(event) => handleToggleSubMenu(event, item.name)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
              {isSubMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          ) : (
            <ListItemButton selected={location.pathname === item.path} LinkComponent={RouterLink} to={item.path}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          )}
          {item.subItems && (
            <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
              <List disablePadding>
                {item.subItems.map((subItem) => (
                  <ListItemButton key={subItem.name} sx={{ pl: 4 }} selected={location.pathname === subItem.path} LinkComponent={RouterLink} to={subItem.path}>
                    <ListItemText primary={subItem.name} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  // Drawer UI
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        textAlign: "center",
        pt: 2,
        px: 1,
        mt: appBarHeight,
        width: drawerWidth,
        height: "100%", // Make the drawer take 100% of available height
      }}>
      <List>{renderNavItems()}</List>
      <Box
        sx={{
          mt: "auto",
          gap: 1,
          mb: 4,
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
        }}>
        <Box>
          <Box component="img" src={IMAGES.LOGO_SHIELD_DARK} sx={{ height: 98, width: 78, opacity: 0.3 }} />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}>
        {drawer}
      </Drawer>
      <Drawer
        open
        elevation={2}
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box" },
        }}>
        {drawer}
      </Drawer>
    </Box>
  );
});

export default CustomDrawer;
