import React from "react";
import { Box, CircularProgress, useTheme, Backdrop, Card } from "@mui/material";
import ReactDOM from "react-dom";
import { Spinner } from "../animations/Spinner";

const OverlayLoader = () => {
  const theme = useTheme();

  return ReactDOM.createPortal(
    <Backdrop open={true} sx={{ color: "#fff", zIndex: theme.zIndex.modal + 1000, position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh" }}>
      <Card sx={{ p: 10 }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2 }}>
          <Spinner />
        </Box>
      </Card>
    </Backdrop>,
    document.body // Mounting point for the portal
  );
};

export default OverlayLoader;
