import PRIVACY_FILE from "../assets/docs/privacy-policy.pdf";
import EULA_FILE from "../assets/docs/end-user-licensing.pdf";

export const QUERIES = {
  Converters: "converters",
  Users: "users",
  VehicleMakes: "vehicleMakes",
  VehicleYears: "vehicleYears",
  VehicleModels: "vehicleModels",
  VehicleEngines: "vehicleEngines",
};

export const IMAGES = {
  LOGO_LIGHT: require("../assets/images/ecosystem-logo-light.png"),
  LOGO_DARK: require("../assets/images/ecosystem-logo-dark.png"),
  LOGO_SHIELD_LIGHT: require("../assets/images/ecosystem-shield-light.png"),
  LOGO_SHIELD_DARK: require("../assets/images/ecosystem-shield-standard.png"),
  CATALYTIC_CONVERTER: require("../assets/images/ucc-catalytic-converter-placeholder.png"),
  BG: require("../assets/images/ecosystem-background.png"),
  AUTH_BG_LEFT: require("../assets/images/auth-left-background.png"),
  AUTH_BG_RIGHT: require("../assets/images/auth-right-background.png"),
};

export const CONFIG_KEYS = {
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_API_TENANT_ID: process.env.REACT_APP_FIREBASE_TENANT_ID,
  API_URL: process.env.REACT_APP_API_URL,
};

export const LOCAL_FILE_DOWNLOAD = {
  PRIVACY_POLICY: PRIVACY_FILE,
  EULA: EULA_FILE 
}

export const friendlyLabels = [
  {
    id: "year-make-model-liters",
    label: "Engine",
  },
  {
    id: "year-make-model-cc",
    label: "Engine",
  },
  {
    id: "year-make-liters",
    label: "Engine",
  },
  {
    id: "year-make-cc",
    label: "Engine",
  },
  {
    id: "make-cc",
    label: "Engine",
  },
  {
    id: "make-liters",
    label: "Engine",
  },
  {
    id: "make",
    label: "Car Manufacturer",
  },
  {
    id: "make-model-liters",
    label: "Engine",
  },
  {
    id: "make-model-cc",
    label: "Engine",
  },
];
