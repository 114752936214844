import { Grid, TextField, Typography, IconButton, Divider } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../utils/constants";
import OverlayLoader from "../ui-components/loaders/OverlayLoader";
import { useFormik } from "formik";
import * as yup from "yup";
import CatalyticConverterService from "../services/converter-service";
import { Clear, Search } from "@mui/icons-material";
import { useState } from "react";
import { ConverterResultCard } from "../ui-components/cards/result-cards/ConverterResultCard";
import { useMemo, useEffect } from "react";
import UnitedEcosystemHeaderText from "../ui-components/UnitedEcosystemHeaderText";

const Converters = () => {
  const [lastSearch, setLastSearch] = useState();

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    validationSchema: yup.object({
      search: yup.string().required("Search is required."),
    }),
    onSubmit: (values) => {
      refetch();
      setLastSearch(values.search);
    },
  });

  useEffect(() => {
    return () => {
      setLastSearch(); // clear last search when component unmounts
    };
  }, []);

  const {
    data: cats,
    isFetching: convertersLoading,
    refetch,
  } = useQuery({
    queryKey: [QUERIES.Converters],
    queryFn: () => CatalyticConverterService.getSimpleConverters(formik.values.search),
    enabled: false,
  });

  const converterResultCards = useMemo(
    () =>
      lastSearch &&
      cats?.data?.result?.map((cat, index) => (
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <ConverterResultCard converterResult={cat} key={index} />
        </Grid>
      )),
    [cats]
  );

  return (
    <>
      <Grid container sx={{ pt: 2 }} spacing={1}>
        <Grid item xs={12}>
          <UnitedEcosystemHeaderText text={"United Ecosystem"} />
          <Typography variant="h4" sx={{ fontSize: 34, fontWeight: 700, lineHeight: "123.5%", letterSpacing: 0.25, mt: 1 }}>
            Converter ID
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item md={3} />
        <Grid item xs={12} sm={6} sx={{ mb: 1 }} component="form" onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            autoComplete="off"
            id="search"
            name="search"
            label="Search"
            value={formik.values.search}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.search && Boolean(formik.errors.search)}
            helperText={formik.touched.search && formik.errors.search}
            InputProps={{
              endAdornment: (
                <IconButton type="submit" onClick={formik.handleSubmit}>
                  <Search />
                </IconButton>
              ),
              startAdornment: formik?.values.search && (
                <IconButton onClick={() => formik.resetForm()}>
                  <Clear />
                </IconButton>
              ),
            }}
          />
          {convertersLoading && <OverlayLoader />}
        </Grid>
        <Grid item xs={12}>
          {lastSearch &&
            (cats?.data?.result?.length === 0 ? (
              <Typography sx={{ fontSize: 16, fontStyle: "italic", fontWeight: 400, lineHeight: "175%", letterSpacing: 0.15 }}>No Results</Typography>
            ) : (
              <Grid item xs={12} mb={1}>
                <Typography sx={{ fontSize: 16, fontStyle: "italic", fontWeight: 400, lineHeight: "175%", letterSpacing: 0.15 }}>
                  Showing {cats?.data?.result?.length} results for "{lastSearch}"
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid container spacing={6} pt={6}>
        {converterResultCards}
      </Grid>
    </>
  );
};

export default Converters;
