import React from "react";
import { Grid, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <>
      <Grid container sx={{ pt: 2, px: 1 }} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">404, Whoops!</Typography>
          <Typography variant="subtitle1">We couldn't find the page you were looking for. Please contact our team for more information.</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default NotFound;
