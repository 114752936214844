import { Box, Typography, Alert, Grid } from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";

export const CascadingSearchAccuracy = ({ label }) => {
  const labelWithIcon = (label, success) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {success ? <CheckCircle color="success" /> : <Cancel color="disabled" />}
        <Typography
          sx={success ? { fontSize: 16, fontWeight: 500, lineHeight: "175%", letterSpacing: "0.15" } : { fontSize: 16, fontWeight: 500, lineHeight: "175%", letterSpacing: "0.15", fontStyle: "italic" }}
          color={success ? "text.primary" : "text.disabled"}>
          {label}
        </Typography>
      </Box>
    );
  };

  return (
    <Alert severity="info" sx={{ borderRadius: 2 }}>
      <Typography sx={{ fontSize: 16, fontWeight: 500, lineHeight: "150%", letterSpacing: 0.15 }}>Results</Typography>
      <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: "143%", letterSpacing: 0.17, mt: 1 }}>Displayed results are based on:</Typography>
      <Box sx={{ display: "flex", alignItems: "center", mt: 1, gap: 2 }}>
        {labelWithIcon("Year", label?.includes("year"))}
        {labelWithIcon("Make", label?.includes("make"))}
        {labelWithIcon("Model", label?.includes("model"))}
        {labelWithIcon("Engine", label?.includes("liters") || label?.includes("cc"))}
      </Box>
    </Alert>
  );
};
