import { Box, Grid, Typography, Card, List, Divider } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { CheckCircle } from "@mui/icons-material";
import { nonZoomableImage, zoomableImage } from "../../../utils/image-helper";
import { IMAGES } from "../../../utils/constants";
import { ConverterIcon } from "../../icons/ConverterIcon";

export const CascadingSearchCard = ({ cascadeDetails, index, anyMostCommonInSearchResult }) => {
  const { totalPrice, catalyticConverters, totalCatalyticConverterQuantity, isMostCommon } = cascadeDetails;

  const groupedConverters = catalyticConverters.reduce((acc, cat) => {
    const key = cat.id;
    if (!acc[key]) {
      acc[key] = { ...cat, count: 0, frontImage: cat.frontImage, backImage: cat.backImage };
    }
    acc[key].count += cat.count;
    return acc;
  }, {});

  const groupedConvertersArray = Object.values(groupedConverters);

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <Card
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 470,
        }}>
        {isMostCommon ? (
          <Box
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              py: 2,
              px: 3,
            }}>
            <CheckCircle />
            <Typography sx={{ fontSize: 18, fontWeight: 700, lineHeight: "150%", letterSpacing: 0.15 }}>Most Common Set</Typography>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "cards.cardHeaderLight",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              py: 2,
              px: 3,
            }}>
            <Typography color="cards.cardHeaderLightContrastText" sx={{ fontSize: 18, fontWeight: 700, lineHeight: "150%", letterSpacing: 0.15 }}>
              Converter Set {anyMostCommonInSearchResult ? index : index + 1}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            p: 3,
          }}>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{
                fontWeight: 700,
                fontSize: 34,
                lineHeight: "123.5%",
                letterSpacing: "0.25px",
              }}>
              <NumericFormat value={totalPrice} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 1 }}>
            <Typography variant="subtitle2" color="text.primary" sx={{ fontSize: 14, fontWeight: 600, lineHeight: "157%", letterSpacing: 0.1 }}>
              Qty of Catalytic Converters:
            </Typography>
            <Typography variant="subtitle2" color="text.primary" sx={{ fontSize: 16, fontWeight: 600, lineHeight: "157%", letterSpacing: 0.1 }}>
              {totalCatalyticConverterQuantity}
            </Typography>
          </Box>
          <Box>
            {Array.from({ length: totalCatalyticConverterQuantity }).map((_, index) => (
              <ConverterIcon key={index} sx={{ mr: 0.5 }} />
            ))}
          </Box>
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              maxHeight: 280,
              pr: totalCatalyticConverterQuantity > 2 ? 2 : 0,
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.1)",
              },
            }}>
            {groupedConvertersArray
              ?.sort((a, b) => (a?.price < b?.price ? 1 : -1))
              .map((cat, index) => {
                return (
                  <Box mb={2}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                      <Box>
                        <Typography color="text.primary" sx={{ fontSize: 14, fontWeight: 500, lineHeight: "171.429%", letterSpacing: 0.17 }}>
                          Qty: {cat?.count} ({cat?.type.name})
                        </Typography>
                      </Box>
                      <Typography color="text.primary" sx={{ fontSize: 16, fontWeight: 600, lineHeight: "162.5%", letterSpacing: 0.46 }}>
                        <NumericFormat value={cat?.price} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
                      </Typography>
                    </Box>
                    <Divider
                      sx={{
                        borderWidth: 1,
                        borderColor: "cards.cardDivider",
                        my: 1,
                      }}
                    />
                    <Box sx={{ display: "flex", gap: 2, my: 1 }}>
                      {cat?.frontImage ? zoomableImage(cat?.frontImage, true) : nonZoomableImage(IMAGES.CATALYTIC_CONVERTER, true)}
                      {cat?.backImage ? zoomableImage(cat?.backImage, true) : nonZoomableImage(IMAGES.CATALYTIC_CONVERTER, true)}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};
