import React from "react";
import { Card, Typography, Button, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

const HomeScreenCard = ({ title, description, buttonLabel, path }) => {
  return (
    <Card elevation={3} sx={{ p: 5, minHeight: 320, display: "flex", flexDirection: "column" }}>
      <Typography sx={{ fontSize: 34, fontWeight: 700, lineHeight: "123.5%", letterSpacing: 0.25 }}>{title}</Typography>
      <Typography sx={{ mt: 3, fontSize: 18, fontWeight: 400, lineHeight: "150%", letterSpacing: 0.15 }}>{description}</Typography>
      <Box sx={{ flex: 1, justifyContent: "flex-end", display: "flex", flexDirection: "column" }}>
        <Button variant="contained" sx={{ mt: 5, borderRadius: 1.5 }} fullWidth size="large" LinkComponent={RouterLink} to={path} endIcon={<ChevronRight sx={{ width: 33, height: 33 }} />}>
          <Typography sx={{ fontSize: 25, fontWeight: 600, lineHeight: "144.444%", letterSpacing: 0.639, textTransform: "uppercase" }}>{buttonLabel}</Typography>
        </Button>
      </Box>
    </Card>
  );
};

export default HomeScreenCard;
