import React from "react";
import { Box, Button, Card, CardContent, Container, Grid, Link, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { auth } from "../utils/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import OverlayLoader from "../ui-components/loaders/OverlayLoader";
import { IMAGES } from "../utils/constants";
import AuthenticationLayout from "../ui-components/layouts/AuthenticationLayout";
import { AuthenticationCard } from "../ui-components/cards/AuthenticationCard";

const validationSchema = yup.object({
  email: yup.string().email("Enter a valid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const Login = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await signInWithEmailAndPassword(auth, values.email, values.password);

        navigate("/");
      } catch (error) {
        formik.setErrors({ email: "Invalid email or password", password: "Invalid email or password" });
      }
    },
  });

  return (
    <AuthenticationLayout>
      {formik.isSubmitting && <OverlayLoader />}
      <AuthenticationCard>
        <Box sx={{ mt: 1.5 }}>
          <Typography sx={{ fontSize: 34, fontWeight: 700, lineHeight: "123.5%", letterSpacing: "0.25px" }}>Sign In</Typography>
          <Typography sx={{ fontSize: 18, fontWeight: 400, lineHeight: "150%", letterSpacing: "0.15px", mt: 1.5 }}>Enter your information below to sign in to the United Ecosystem.</Typography>
          <Box mt={5}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="E-Mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
              />
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                margin="normal"
              />
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1 }}>
                <Link component={RouterLink} underline="none" to="/forgot-password" variant="body2">
                  Forgot Password
                </Link>
              </Box>
              <Button sx={{ mt: 5 }} size="large" color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
                <Typography sx={{ fontSize: 25, fontWeight: 600, lineHeight: "144.444%", letterSpacing: "0.639px" }}>SIGN IN</Typography>
              </Button>
            </form>
          </Box>
        </Box>
      </AuthenticationCard>
    </AuthenticationLayout>
  );
};

export default Login;
