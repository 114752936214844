import { Grid, Typography, useTheme, FormControl, InputLabel, Select, MenuItem, Box, Divider } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../utils/constants";
import OverlayLoader from "../ui-components/loaders/OverlayLoader";
import CatalyticConverterService from "../services/converter-service";
import { useState } from "react";
import vehicleService from "../services/vehicle-service";
import { CascadingSearchAccuracy } from "../ui-components/CascadingSearchAccuracy";
import { NumericFormat } from "react-number-format";
import { CascadingSearchCard } from "../ui-components/cards/result-cards/CascadingSearchCard";
import UnitedEcosystemHeaderText from "../ui-components/UnitedEcosystemHeaderText";
import { Spinner } from "../ui-components/animations/Spinner";
import { ArrowDropDown } from "@mui/icons-material";
import { useMemo } from "react";

const VehiclesYmme = () => {
  const theme = useTheme();
  const [filter, setFilter] = useState({
    year: null,
    make: null,
    model: null,
    engineLiters: null,
  });

  const onlyApproved = true; // TBD we will change this pending business decision.

  const { data: vehicleYears, isFetching: vehicleYearsLoading } = useQuery({ queryKey: [QUERIES.VehicleYears], queryFn: () => vehicleService.getYears(), enabled: true });
  const { data: vehicleMakes, isFetching: vehicleMakesLoading } = useQuery({ queryKey: [QUERIES.VehicleMakes, filter.year], queryFn: () => vehicleService.getMakes([filter.year], onlyApproved), enabled: !!filter.year });
  const { data: vehicleModels, isFetching: vehicleModelsLoading } = useQuery({
    queryKey: [QUERIES.VehicleModels, filter.year, filter.make],
    queryFn: () => vehicleService.getModels([filter.year], [filter.make], onlyApproved),
    enabled: !!filter.year && !!filter.make,
  });
  const { data: vehicleEngines, isFetching: vehicleEnginesLoading } = useQuery({
    queryKey: [QUERIES.VehicleEngines, filter.year, filter.make, filter.model],
    queryFn: () => vehicleService.getEngines([filter.year], [filter.make], [filter.model], onlyApproved),
    enabled: !!filter.year && !!filter.make && !!filter.model,
  });

  const { data: catalyticConverterSets, isFetching: catalyticConverterSetsLoading } = useQuery({
    queryKey: [QUERIES.Converters, "ymme", filter.year, filter.make, filter.model, filter.engineLiters],
    queryFn: () => CatalyticConverterService.getCascadingSearchSimpleYearMakeModelEngine(filter.year, filter.make, filter.model, filter.engineLiters),
    enabled: !!filter.year && !!filter.make && !!filter.model && !!filter.engineLiters,
  });

  const handleYearChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      year: value,
    });
  };

  const handleMakesChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      year: filter.year,
      make: value,
    });
  };

  const handleModelsChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      year: filter.year,
      make: filter.make,
      model: value,
    });
  };

  const handleEnginesChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilter({
      ...filter,
      engineLiters: value,
    });
  };

  const firstResult = catalyticConverterSets?.data?.result;
  const cascadingSearchResultCards = useMemo(() => firstResult?.results?.map((cascadeResult, index) => <CascadingSearchCard cascadeDetails={cascadeResult} index={index} key={index} />), [firstResult]);

  return (
    <>
      {catalyticConverterSetsLoading && <OverlayLoader />}
      <Grid container sx={{ pt: 2, mb: 5 }} spacing={1}>
        <Grid item xs={12}>
          <UnitedEcosystemHeaderText text={"United Ecosystem"} />
          <Typography variant="h4" sx={{ fontSize: 34, fontWeight: 700, lineHeight: "123.5%", letterSpacing: 0.25, mt: 1 }}>
            YMME Search
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item md={3} />
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ fontSize: 16, fontWeight: 400, lineHeight: "175%", letterSpacing: 0.15, fontStyle: "italic" }}>
            This YMME Tool is still a prototype. Please enjoy testing it out!
          </Typography>
          <Typography variant="subtitle1" sx={{ fontSize: 16, fontWeight: 400, lineHeight: "175%", letterSpacing: 0.15, fontStyle: "italic" }}>
            The data you see here is accurate, but it is only a small sample set of our full database.
          </Typography>
          <Typography variant="subtitle1" sx={{ fontSize: 16, fontWeight: 400, lineHeight: "175%", letterSpacing: 0.15, fontStyle: "italic" }}>
            Your feedback would be highly appreciated. Call (864)834-2003 to tell us what you think!
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="years-label">Year</InputLabel>
            <Select labelId="years-label" id="years-select" value={filter.year} onChange={handleYearChange} IconComponent={() => (vehicleYearsLoading ? <Spinner size={24} /> : <ArrowDropDown color="action" />)}>
              {vehicleYears?.data?.result &&
                vehicleYears?.data?.result
                  ?.sort((a, b) => b - a)
                  ?.map((year) => (
                    <MenuItem key={`year-id-${year}`} value={year}>
                      {year}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <FormControl fullWidth variant="standard" disabled={!filter.year}>
            <InputLabel id="makes-label" variant="standard">
              Make
            </InputLabel>
            <Select labelId="makes-label" id="makes-select" value={filter.make} onChange={handleMakesChange} IconComponent={() => (vehicleMakesLoading ? <Spinner size={24} /> : <ArrowDropDown color="action" />)}>
              {vehicleMakes?.data?.result &&
                vehicleMakes?.data?.result
                  ?.sort((a, b) => b - a)
                  ?.map((make) => (
                    <MenuItem key={`makes-id-${make}`} value={make}>
                      {make}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <FormControl fullWidth variant="standard" disabled={!filter.make}>
            <InputLabel id="models-label">Model</InputLabel>
            <Select labelId="models-label" id="models-select" value={filter.model} onChange={handleModelsChange} color="primary" IconComponent={() => (vehicleModelsLoading ? <Spinner size={24} /> : <ArrowDropDown color="action" />)}>
              {vehicleModels?.data?.result &&
                vehicleModels?.data?.result
                  ?.sort((a, b) => b - a)
                  ?.map((model) => (
                    <MenuItem key={`model-id-${model}`} value={model}>
                      {model}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <FormControl fullWidth variant="standard" disabled={!filter.model}>
            <InputLabel id="engines-label">Engine</InputLabel>
            <Select labelId="engines-label" id="engines-select" value={filter.engineLiters} onChange={handleEnginesChange} IconComponent={() => (vehicleEnginesLoading ? <Spinner size={24} /> : <ArrowDropDown color="action" />)}>
              {vehicleEngines?.data?.result &&
                vehicleEngines?.data?.result
                  ?.sort((a, b) => b - a)
                  ?.map((engine) => (
                    <MenuItem key={`engine-id-${engine}`} value={engine}>
                      {engine}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container xs={12} mb={1} mt={3}>
        <Grid item sm={12} md={6} xl={8} alignContent="end">
          {firstResult?.vehicle && (
            <Typography color="primary" sx={{ fontSize: 16, fontWeight: 700, lineHeight: "150%", letterSpacing: 0.15 }}>
              {firstResult?.vehicle?.year} {firstResult?.vehicle?.make} {firstResult?.vehicle?.model} {firstResult?.vehicle?.engineLiters}L
            </Typography>
          )}
          <Box display="flex" flexDirection="row">
            {firstResult?.mostCommonPrice && (
              <Typography color="primary" sx={{ fontSize: 60, fontWeight: 500, lineHeight: "120%", letterSpacing: -0.5 }}>
                <NumericFormat value={firstResult?.mostCommonPrice} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
              </Typography>
            )}
            {firstResult?.lowestPrice !== firstResult?.highestPrice && (
              <Box display="flex" flexDirection="column" ml={2} mt={1}>
                <Typography sx={{ fontSize: 18, fontWeight: 400, lineHeight: "150%", letterSpacing: 0.15 }} color="primary">
                  Price Range
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: 400, lineHeight: "150%", letterSpacing: 0.15 }} color="primary">
                  <NumericFormat value={firstResult?.lowestPrice} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
                  {" - "}
                  <NumericFormat value={firstResult?.highestPrice} displayType={"text"} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={"$"} />
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={6} pt={6}>
        {cascadingSearchResultCards}
      </Grid>
    </>
  );
};

export default VehiclesYmme;
