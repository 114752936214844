import React, { useState } from "react";
import { Alert, AlertTitle, Box, Button, Card, CardContent, Container, Grid, Link, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { auth } from "../utils/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import OverlayLoader from "../ui-components/loaders/OverlayLoader";

const validationSchema = yup.object({
  email: yup.string().email("Enter a valid email").required("Email is required"),
});

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await sendPasswordResetEmail(auth, values.email);
        setSuccess(true);
      } catch (error) {
        formik.setErrors({ email: "Invalid email" });
      }
    },
  });

  return (
    <Container maxWidth={false}>
      <Grid container>
        <Grid item sm={1} md={3} lg={4}></Grid>
        <Grid item xs={12} sm={10} md={6} lg={4}>
          {formik.isSubmitting && <OverlayLoader />}
          <Card
            variant="outlined"
            sx={{
              px: 2,
              py: 4,
              mt: 4,
            }}>
            <CardContent>
              <Typography mb={1} variant="h4">
                Forgot Password
              </Typography>
              {success ? (
                <Alert severity="success">
                  <AlertTitle>Success</AlertTitle>
                  We have sent you an email with instructions on how to reset your password.
                </Alert>
              ) : (
                <Typography mb={2} variant="body1">
                  Enter your email below and we will send you instructions on how to reset your password.
                </Typography>
              )}
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email Address"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  margin="normal"
                />
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1 }}>
                  <Link component={RouterLink} underline="none" to="/login" variant="body2">
                    Back to Login
                  </Link>
                </Box>
                <Button sx={{ mt: 3 }} size="large" color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
                  SEND RESET
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;
